const content = {
  title: 'Datenschutz',
  headvisualHeadline: 'Datenschutz',
  headline: 'Inhalt fehlt noch',
  paragraphs: [
    'The quick brown fox jumps over the lazy dog.The quick brown fox jumps over the lazy dog.The quick brown fox jumps over the lazy dog.The quick brown fox jumps over the lazy dog./br></br>'
  ]
};

export default content;
