import React from 'react';

import { graphql } from 'gatsby';

import MainLayout from 'src/layout/MainLayout';
import { SectionHead, Paragraphs } from 'src/components';

import content from 'src/content/datenschutz';


const DatenschutzPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline
    }}
  >
    <section className="text-dark-blue">
      <SectionHead headline={content.headline} />
      <div className="container container--simple">
        <Paragraphs items={content.paragraphs} innerHTML />
      </div>
    </section>
  </MainLayout>
);

export default DatenschutzPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-leaning-on-window-with-coffee.jpg" }
    ) {
      ...image
    }
    womanSmilingWithTablet: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-smiling-with-tablet.jpg" }
    ) {
      ...image
    }
  }
`;
